import React, {memo} from "react";
import styles from './moduleStatistics.module.scss';
import {ModuleProgressAtom} from "../../atoms/moduleProgress/moduleProgress";
import {Panel} from "rsuite";
import {ModuleStatisticsMoleculeProps} from "./moduleStatistics.types";

const ModuleStatisticsMolecule = memo<ModuleStatisticsMoleculeProps>((props) => {
  const renderProgress = () => {
    if( !props.showProgress || props.progressMaxCount == null || props.progressCurrentCount == null ) {
      return null;
    }

    return (
      <ModuleProgressAtom current={props.progressCurrentCount} max={props.progressMaxCount}/>
    );
  }

  return (
    <Panel
      className={styles.statisticsPanel}
      bodyFill={true}
      bordered={true}
      header={<h5>{props.title}</h5>}
    >
      {renderProgress()}
    </Panel>
  )
});

export default ModuleStatisticsMolecule;
export {ModuleStatisticsMolecule};
