import React, {memo, NamedExoticComponent, useRef, useState} from "react";
import {Button, ButtonToolbar, ControlLabel, FlexboxGrid, Form, FormControl, FormGroup, Panel, Schema} from "rsuite";
import styles from './registerPage.module.scss';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {FormInstance} from "rsuite/es/Form";
import {useHistory} from "react-router";
import PagePath from "../../../../routing/pagePath";
import {Account} from "../../../../services/api-service";

export const RegisterPage: NamedExoticComponent = memo(() => {
  const {t} = useTranslation();
  const history = useHistory();

  const { StringType } = Schema.Types;
  const model = Schema.Model({
    email: StringType()
      .isEmail(t('emailInvalid'))
      .isRequired(t('emailRequiredError')),
    password: StringType()
      .isRequired(t('passwordRequiredError'))
      .minLength(6, t('passwordMinLengthInvalid'))
      .maxLength(64, t('passwordMaxLengthInvalid'))
  });

  const formRef = useRef<FormInstance>(null);
  const [registering, setRegisterLoadingState] = useState(false);
  const [formState, setFormState] = useState({
    email: '',
    password: ''
  });

  const register = async () => {
    if( formRef.current === null ) {
      return;
    }

    let result = await formRef.current.checkAsync();

    if(result.hasError) {
      return;
    }

    setRegisterLoadingState(true);

    try {
      await Account.accountRegister(formState.email, formState.password);
      history.replace(PagePath.PRODUCTS);
    } finally {
      setRegisterLoadingState(false);
    }
  };

  return (
    <FlexboxGrid justify="center" align={"middle"} className={styles.wrapper}>

      <Panel header={t('RegisterScreenHeading')} bordered={true} shaded={false} className={styles.container}>

        <Form
          fluid={true}
          model={model}
          ref={formRef}
          onChange={formValue => {
            setFormState(formValue as any);
          }}
          formValue={formState}
        >
          <FormGroup>
            <ControlLabel>{t('email')}</ControlLabel>
            <FormControl
              name="email"
              type={'email'}
              errorPlacement={"bottomStart"}
              checkTrigger={"blur"}
              disabled={registering}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>{t('password')}</ControlLabel>
            <FormControl
              name="password"
              type="password"
              errorPlacement={"bottomStart"}
              checkTrigger={"blur"}
              disabled={registering}
            />
          </FormGroup>
          <FormGroup>
            <ButtonToolbar className={styles.centerizeFirst}>
              <Button appearance="primary" disabled={registering} loading={registering} type="submit" onClick={register}>
                {t('register')}
              </Button>
            </ButtonToolbar>
            <div className={styles.or}>
              {t('or')}
            </div>
            <ButtonToolbar className={styles.centerizeLast}>
              <Button appearance="ghost" componentClass={Link} to={PagePath.LOGIN} disabled={registering}>
                {t('login')}
              </Button>
              <Button appearance="link" componentClass={Link} to={PagePath.LOST_PASSWORD} disabled={registering}>
                {t('lostPassword')}
              </Button>
            </ButtonToolbar>
          </FormGroup>
        </Form>

      </Panel>

    </FlexboxGrid>
  )
})
