import { GuardProvider, GuardedRoute } from 'react-router-guards';
import {Switch} from 'react-router-dom';
import {Error404Page} from "../components/pages/public/error404Page/error404Page";
import {Loader} from "rsuite";
import {DashboardLayout} from "../components/templates/dashboardLayout/dashboardLayout";
import {RequireLogin} from "./requireLoginMiddleware";
import PagePath from "./pagePath";
import ProductsAndProductUsages from "../components/pages/authorized/productsAndProductUsages/productsAndProductUsages";
import ProductUsageDetailsPage from "../components/pages/authorized/productUsageDetails/productUsageDetails";

export const DashboardRouting = () => {
  return (
    <GuardProvider guards={[RequireLogin]} loading={Loader} error={Error404Page}>
      <DashboardLayout>
        <Switch>
          <GuardedRoute path={PagePath.PRODUCT_USAGE_DETAILS} exact component={ProductUsageDetailsPage} />
          <GuardedRoute path={PagePath.PRODUCTS} exact component={ProductsAndProductUsages} />
          <GuardedRoute path={PagePath.HOME} exact component={ProductsAndProductUsages} />
        </Switch>
      </DashboardLayout>
    </GuardProvider>
  )
};
