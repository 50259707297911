import React, {memo, useEffect} from "react";
import {ErrorTemplate} from "../../../templates/error/errorTemplate";
import {useTranslation} from "react-i18next";
import Recovered from "../../../../assets/lottie/lock-action.json";
import LottiePlay from "lottie-react";
import styles from './passwordRecoveredPage.module.scss';
import {useHistory} from "react-router";
import PagePath from "../../../../routing/pagePath";

export const PasswordRecoveredPage = memo(() => {
  const {t} = useTranslation();
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.replace(PagePath.PRODUCTS);
    }, 3000);
  }, [history]);

  return (
    <ErrorTemplate
      image={
        <LottiePlay
          animationData={Recovered}
          className={styles.lottiePlay}
        />
      }
      heading={t('successfullyRecoveredHeading')}
      description={t('successfullyRecoveredDescription')}
    />
  )
});
