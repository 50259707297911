import {memo, useMemo} from "react";
import styles from './moduleProgress.module.scss';
import { Progress } from 'rsuite';
import {ModuleProgressAtomProps} from "./moduleProgress.types";
import {useThemeSwitcher} from "react-css-theme-switcher";

const ModuleProgressAtom = memo<ModuleProgressAtomProps>((props) => {
  const percent = useMemo(() => props.current / props.max * 100, [props]);
  const {currentTheme} = useThemeSwitcher();

  return (
    <Progress.Line
      className={styles.countBox}
      percent={percent}
      strokeWidth={6}
      strokeLinecap={'round'}
      strokeColor={currentTheme === 'dark' ? '#EEE' : '#555'}
      showInfo={false}
    />
  )
});

export default ModuleProgressAtom;
export {ModuleProgressAtom};
