import { GuardProvider, GuardedRoute } from 'react-router-guards';
import {BrowserRouter, Switch} from 'react-router-dom';
import {Error404Page} from "../components/pages/public/error404Page/error404Page";
import {Loader} from "rsuite";
import {DashboardRouting} from "./dashboardRouting";
import PagePath, {DynamicPagePath} from "./pagePath";
import {AuthRouting} from "./authRouting";
import {RequireLogin} from "./requireLoginMiddleware";

export const MainRouting = () => {
  return (
    <BrowserRouter>
      <GuardProvider guards={[RequireLogin]} loading={Loader} error={Error404Page}>
        <Switch>
          <GuardedRoute path={DynamicPagePath.AUTH_ITEMS} component={AuthRouting} />
          <GuardedRoute path={DynamicPagePath.DASHBOARD_ITEMS} component={DashboardRouting} meta={{ auth: true }} />
          <GuardedRoute path={PagePath.HOME} exact component={DashboardRouting} meta={{ auth: true }} />
          <GuardedRoute path="*" component={Error404Page} />
        </Switch>
      </GuardProvider>
    </BrowserRouter>
  )
};
