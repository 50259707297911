import React from 'react';

import '../../../initializeProject';
import { Helmet } from 'react-helmet';
import {MainRouting} from "../../../routing/mainRouting";

export const Entry = () => {
  return (
    <>
      <Helmet>
        <style type="text/css">{`
          html, body, #root {
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
          }
      `}</style>
      </Helmet>

      <MainRouting/>
    </>
  );
}
