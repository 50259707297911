import React, {memo, NamedExoticComponent, useRef, useState} from "react";
import {Button, ButtonToolbar, ControlLabel, FlexboxGrid, Form, FormControl, FormGroup, Panel, Schema} from "rsuite";
import styles from './recoverPasswordPage.module.scss';
import {useTranslation} from "react-i18next";
import {Link, useLocation} from 'react-router-dom';
import {FormInstance} from "rsuite/es/Form";
import {useHistory} from "react-router";
import PagePath from "../../../../routing/pagePath";
import {Account} from "../../../../services/api-service";
import {RecoverPasswordPageParams} from "./recoverPasswordPage.types";

export const RecoverPasswordPage: NamedExoticComponent = memo(() => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation<RecoverPasswordPageParams>();

  const { StringType } = Schema.Types;
  const model = Schema.Model({
    code: StringType()
      .isRequired(t('codeRequiredError'))
      .rangeLength(6, 6, t('codeLengthInvalid')),
    password: StringType()
      .isRequired(t('passwordRequiredError'))
      .minLength(6, t('passwordMinLengthInvalid'))
      .maxLength(64, t('passwordMaxLengthInvalid')),
    verifyPassword: StringType()
      .addRule((value, data) => {
        return value === data.password;
      }, t('passwordsDoesNotMatch'))
      .isRequired(t('passwordRequiredError'))
      .minLength(6, t('passwordMinLengthInvalid'))
      .maxLength(64, t('passwordMaxLengthInvalid')),
  });

  const formRef = useRef<FormInstance>(null);
  const [recovering, setRecoveringState] = useState(false);
  const [formState, setFormState] = useState({
    code: '',
    password: '',
    verifyPassword: ''
  });

  const recoverAccount = async () => {
    if( typeof location.state.email === 'undefined' ) {
      return;
    }

    if( formRef.current === null ) {
      return;
    }

    let result = await formRef.current.checkAsync();

    if(result.hasError) {
      return;
    }

    setRecoveringState(true);

    try {
      await Account.accountRecoverPassword(location.state.email, formState.code, formState.password);
      history.replace(PagePath.PASSWORD_RECOVERED);
    } finally {
      setRecoveringState(false);
    }
  };

  return (
    <FlexboxGrid justify="center" align={"middle"} className={styles.wrapper}>

      <Panel header={t('recoverPasswordHeading')} bordered={true} shaded={false} className={styles.container}>

        <Form
          fluid={true}
          model={model}
          ref={formRef}
          onChange={formValue => {
            setFormState(formValue as any);
          }}
          formValue={formState}
        >
          <FormGroup>
            <ControlLabel>{t('recoveryCode')}</ControlLabel>
            <FormControl
              name="code"
              type={'text'}
              errorPlacement={"bottomStart"}
              checkTrigger={"blur"}
              disabled={recovering}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>{t('password')}</ControlLabel>
            <FormControl
              name="password"
              type="password"
              errorPlacement={"bottomStart"}
              checkTrigger={"blur"}
              disabled={recovering}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>{t('verifyPassword')}</ControlLabel>
            <FormControl
              name="verifyPassword"
              type="password"
              errorPlacement={"bottomStart"}
              checkTrigger={"blur"}
              disabled={recovering}
            />
          </FormGroup>
          <FormGroup>
            <ButtonToolbar className={styles.centerizeFirst}>
              <Button appearance="primary" disabled={recovering} loading={recovering} type="submit" onClick={recoverAccount}>
                {t('recoverMyAccount')}
              </Button>
            </ButtonToolbar>
            <div className={styles.or}>
              {t('or')}
            </div>
            <ButtonToolbar className={styles.centerizeLast}>
              <Button appearance="ghost" componentClass={Link} to={PagePath.LOGIN} disabled={recovering}>
                {t('cancel')}
              </Button>
            </ButtonToolbar>
          </FormGroup>
        </Form>

      </Panel>

    </FlexboxGrid>
  )
})
