import { GuardProvider, GuardedRoute } from 'react-router-guards';
import {Switch} from 'react-router-dom';
import {Error404Page} from "../components/pages/public/error404Page/error404Page";
import {Loader} from "rsuite";
import PagePath from "./pagePath";
import {LoginPage} from "../components/pages/public/loginPage/loginPage";
import {RegisterPage} from "../components/pages/public/registerPage/registerPage";
import {LostPasswordPage} from "../components/pages/public/lostPasswordPage/lostPasswordPage";
import {RecoverPasswordPage} from "../components/pages/public/recoverPasswordPage/recoverPasswordPage";
import {PasswordRecoveredPage} from "../components/pages/public/passwordRecoveredPage/passwordRecoveredPage";
import {RequireLogin} from "./requireLoginMiddleware";
import {AuthLayout} from "../components/templates/authLayout/authLayout";

export const AuthRouting = () => {
  return (
    <GuardProvider guards={[RequireLogin]} loading={Loader} error={Error404Page}>
      <AuthLayout>
        <Switch>
          <GuardedRoute path={PagePath.LOGIN} exact component={LoginPage} />
          <GuardedRoute path={PagePath.REGISTER} exact component={RegisterPage} />
          <GuardedRoute path={PagePath.LOST_PASSWORD} exact component={LostPasswordPage} />
          <GuardedRoute path={PagePath.RECOVER_PASSWORD} exact component={RecoverPasswordPage} />
          <GuardedRoute path={PagePath.PASSWORD_RECOVERED} exact component={PasswordRecoveredPage} />
          <GuardedRoute path={PagePath.HOME} exact component={LoginPage} />
        </Switch>
      </AuthLayout>
    </GuardProvider>
  )
};
