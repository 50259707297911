import React, {useMemo} from "react";
import {memo} from "react";
import {Button, FlexboxGrid, Header, Icon, SelectPicker, Toggle} from "rsuite";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {localStorageHelper} from "../../../helpers/localStorage";
import PagePath from "../../../routing/pagePath";
import styles from "./headerOrganism.module.scss";
import {useThemeSwitcher} from "react-css-theme-switcher";
import {getAvailableLanguages} from "../../../translations/resources";

const HeaderOrganism = memo(() => {
  const history = useHistory();
  const {t, i18n} = useTranslation();
  const { switcher, themes, currentTheme, status } = useThemeSwitcher();

  const languageData = useMemo(() => {
    const allLanguages = getAvailableLanguages();

    return allLanguages.filter(l => l.shortName !== i18n.language).map(l => {
      return {
        label: l.nativeName,
        value: l.shortName
      }
    });
  }, [i18n.language]);

  const currentLanguageName = useMemo(() => {
    const allLanguages = getAvailableLanguages();
    return allLanguages.find(l => l.shortName === i18n.language)!.nativeName;
  }, [i18n.language]);

  if (status === 'loading') {
    return <div>Loading styles...</div>;
  }

  const renderLogoutButton = () => {
    if( localStorageHelper.UserJwt === null ) {
      return null;
    }

    return (
      <Button appearance={"ghost"} onClick={logout} className={styles.rightHeaderItem}>
        {t('logout')}
      </Button>
    );
  }

  const toggleDarkMode = () => {
    const newTheme = currentTheme === 'dark' ? themes.light : themes.dark;
    switcher({ theme: newTheme });
    localStorageHelper.UiTheme = newTheme;
  };

  const changeLanguage = async (newLanguageCode: any) => {
    console.log(newLanguageCode);
    await i18n.changeLanguage(newLanguageCode);
  }

  const getLogoStyles = () => {
    const classNames = ['fosh-logo', styles.logo];

    if( currentTheme === 'dark' ) {
      classNames.push(styles.white);
    }

    return classNames.join(' ');
  }

  const logout = () => {
    localStorageHelper.UserJwt = null;
    history.replace(PagePath.LOGIN);
  }

  return (
  <Header appearance="subtle">
    <FlexboxGrid justify={'space-between'} align={"middle"} className={styles.header}>
      <FlexboxGrid.Item>
        <i className={getLogoStyles()}/>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item>

        <SelectPicker
          appearance={"subtle"}
          searchable={false}
          placeholder={currentLanguageName}
          menuAutoWidth={true}
          data={languageData}
          onSelect={changeLanguage}
          className={styles.rightHeaderItem}
        />

        <Toggle
          checked={currentTheme!=='dark'}
          size={'lg'}
          checkedChildren={<Icon icon="sun-o" />}
          unCheckedChildren={<Icon icon="moon-o" />}
          onChange={toggleDarkMode}
          className={styles.rightHeaderItem}
        />

        {renderLogoutButton()}

      </FlexboxGrid.Item>
    </FlexboxGrid>
  </Header>
  )
});

export default HeaderOrganism;
export {HeaderOrganism};
