import axios, { AxiosError, AxiosResponse } from 'axios';
import * as Services from '@foshteam/fosh-saas-api-client';
import {Notification} from 'rsuite';

import { Config } from '../config';
import { localStorageHelper } from "../helpers/localStorage";

const onRequestSucceed = (response: AxiosResponse): AxiosResponse => {
  if( typeof response.data.jwt !== 'undefined' && response.data.jwt.length > 0 ) {
    localStorageHelper.UserJwt = response.data.jwt;
  }

  return response;
};

const onRequestFailed = (error: AxiosError) => {

  if( error.isAxiosError ) {
    Notification.error({
      title: 'Message',
      placement: 'bottomEnd',
      description: `${error.response?.data.userMessageCode}`
    });
  }

  throw error;
};

const onRequest = (data: any) => {
  if (localStorageHelper.UserJwt !== null) {
    data.headers.Authorization = `Bearer ${localStorageHelper.UserJwt}`;
  }

  return data;
};

axios.interceptors.request.use(onRequest);
axios.interceptors.response.use(onRequestSucceed, onRequestFailed);

export const Account = Services.AccountApiFactory(undefined, Config.api.baseUrl, axios);
export const ProductUsage = Services.ProductUsageApiFactory(undefined, Config.api.baseUrl, axios);
export const Administration = Services.AdministrationApiFactory(undefined, Config.api.baseUrl, axios);
export const CustomerAdministration = Services.CustomerAdministrationApiFactory(undefined, Config.api.baseUrl, axios);
export const Product = Services.ProductApiFactory(undefined, Config.api.baseUrl, axios);
