import React, {memo, useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {ProductUsage} from '../../../../services/api-service';
import PagePath from "../../../../routing/pagePath";
import {Button, Col, FlexboxGrid, Icon, Input, InputGroup, Loader, Row, Tooltip, Whisper, Notification} from "rsuite";
import {useTranslation} from "react-i18next";
import styles from './productUsageDetails.module.scss';
import ModuleStatisticsMolecule from "../../../molecules/moduleStatistics/moduleStatistics";
import LottiePlay from "lottie-react";
import ProductNotFound from "../../../../assets/lottie/product-not-found.json";
import {ErrorTemplate} from "../../../templates/error/errorTemplate";
import useCopy from "use-copy";
import {timeout} from "../../../../utils";
import {ProductUsageDetailsParams} from './productUsageDetails.types';
import {
  ProductUsageQueriesGetProductUsageDetailsQueryResult
} from '@foshteam/fosh-saas-api-client';

const ProductUsageDetailsPage = memo(() => {
  const {id} = useParams<ProductUsageDetailsParams>();
  const {t} = useTranslation();
  const [isLoading, setLoadingState] = useState(true);
  const [isFailed, setFailState] = useState(false);
  const [isSecretVisible, setSecretVisibility] = useState(false);
  const [secretKeyRegenerating, setSecretKeyRegenerateState] = useState(false);
  const [changingDomain, setDomainChangeState] = useState(false);
  const [productUsageDetails, setProductUsageDetails] = useState<ProductUsageQueriesGetProductUsageDetailsQueryResult|null>(null);
  const {replace} = useHistory();

  const [tempDomain, setTempDomain] = useState<string|undefined>(productUsageDetails && productUsageDetails.activeDomain ? productUsageDetails.activeDomain : "");

  const [apiKeyCopied, copyApiKey, setApiKeyCopied] = useCopy(productUsageDetails && productUsageDetails.apiKey ? productUsageDetails.apiKey : "");
  const [appSecretCopied, copyAppSecret, setAppSecretCopied] = useCopy(productUsageDetails && productUsageDetails.secretKey ? productUsageDetails.secretKey : "");
  const [domainCopied, copyDomain, setDomainCopied] = useCopy(tempDomain ?? '');

  const copyApiKeyHandler = () => {
    copyApiKey();

    const timeoutKey = setTimeout(() => {
      setApiKeyCopied(false);
    }, 2000);

    return () => {
      setApiKeyCopied(false);
      clearTimeout(timeoutKey);
    }
  };

  const copyAppSecretHandler = () => {
    copyAppSecret();

    const timeoutKey = setTimeout(() => {
      setAppSecretCopied(false);
    }, 2000);

    return () => {
      setAppSecretCopied(false);
      clearTimeout(timeoutKey);
    }
  };

  const copyDomainHandler = () => {
    copyDomain();

    const timeoutKey = setTimeout(() => {
      setDomainCopied(false);
    }, 2000);

    return () => {
      setDomainCopied(false);
      clearTimeout(timeoutKey);
    }
  };

  const loadProduct = useCallback(async () => {
    if( typeof id === 'undefined' ) {
      replace(PagePath.NOT_FOUND);
      return;
    }

    setLoadingState(true);
    setFailState(false);
    try {
      const result = await ProductUsage.getProductUsageDetails(id);
      setProductUsageDetails(result.data);
      setTempDomain(result.data.activeDomain ?? '');
    } catch(e) {
      setFailState(true);
    } finally {
      setLoadingState(false);
    }
  }, [id, replace]);

  const onTempDomainChanged = (newDomain: string) => {
    setTempDomain(newDomain);
  }

  const onRetryButtonPressed = async () => {
    await loadProduct();
  }

  useEffect(() => {
    (async () => {
      await loadProduct();
    })();
  }, [loadProduct]);

  if( isLoading ) {
    return (
      <FlexboxGrid justify={"center"} align={"middle"} className={styles.loadingArea}>
        <FlexboxGrid.Item>
          <Loader
            content={t('productLoading')}
            vertical={true}
            size="md"
            center={false}
            backdrop={false}
            speed={"normal"}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>

    )
  }

  if( isFailed || productUsageDetails == null ) {
    return (
      <ErrorTemplate
        image={
          <LottiePlay
            animationData={ProductNotFound}
            className={styles.lottiePlay}
          />
        }
        heading={t('productLoadFailedHeading')}
        description={t('productLoadFailedDescription')}
        footer={
          <Button size={"lg"} appearance={"primary"} className={styles.retryButton} onClick={onRetryButtonPressed}>
            {t('retry')}
          </Button>
        }
      />
    )
  }

  const toggleSecretKeyVisibility = () => {
    setSecretVisibility(!isSecretVisible);
  }

  const appSecretText = (() => {
    if( isSecretVisible ) {
      return productUsageDetails.secretKey;
    }

    return new Array(32).fill('●').join('');
  })();

  const regenerateSecretKey = async () => {
    setSecretKeyRegenerateState(true);

    await timeout(2000);

    try {
      const result = await ProductUsage.productRegenerateKey(productUsageDetails.productUsageId);

      setProductUsageDetails({
        ...productUsageDetails,
        secretKey: result.data.generatedSecretKey
      });

      setSecretVisibility(true);
    } catch (e) {
      Notification.warning({
        title: t('regenerateSecretKeyErrorHeading'),
        description: t('regenerateSecretKeyError')
      });
    } finally {
      setSecretKeyRegenerateState(false);
    }
  }

  const regenerateAddon = (() => {
    if( secretKeyRegenerating ) {
      return (
        <Whisper
          trigger="hover"
          placement={"top"}
          speaker={
            <Tooltip>
              {t('regenerating')}
            </Tooltip>
          }
        >
          <Loader speed={'normal'} className={styles.fixedWidthLoader}/>
        </Whisper>
      )
    }

    return (
      <Whisper
        trigger="hover"
        placement={"top"}
        speaker={
          <Tooltip>
            {t('regenerate')}
          </Tooltip>
        }
      >
        <i className={styles.inputAddon} onClick={regenerateSecretKey}>
          <Icon fixedWidth={true} icon="refresh" />
        </i>
      </Whisper>
    )
  })()

  const saveNewDomain = async () => {
    setDomainChangeState(true);

    await timeout(2000);

    try {
      await ProductUsage.productChangeDomain(productUsageDetails.productUsageId, tempDomain);

      setProductUsageDetails({
        ...productUsageDetails,
        activeDomain: tempDomain
      });
    } finally {
      setDomainChangeState(false);
    }
  }

  const isDomainChanged = (() => productUsageDetails.activeDomain !== tempDomain)()

  const saveDomainAddon = (() => {
    if( changingDomain ) {
      return (
        <Whisper
          trigger="hover"
          placement={"top"}
          speaker={
            <Tooltip>
              {t('savingNewDomain')}
            </Tooltip>
          }
        >
          <Loader speed={'normal'} className={styles.fixedWidthLoader}/>
        </Whisper>
      );
    }

    if( isDomainChanged ) {
      return (
        <Whisper
          trigger="hover"
          placement={"top"}
          speaker={
            <Tooltip>
              {t('saveChanges')}
            </Tooltip>
          }
        >
          <i className={styles.inputAddon} onClick={saveNewDomain}>
            <Icon fixedWidth={true} icon={"save"} />
          </i>
        </Whisper>
      );
    }

    return null;
  })();

  return (
    <Row>
      <Col xs={24} md={12}>

        <h2>
          {productUsageDetails.title ? t(productUsageDetails.title) : ''}
        </h2>

        <p className={styles.productDescription}>
          {productUsageDetails.description ? t(productUsageDetails.description) : ''}
        </p>

        <h5 className={styles.appInfoRowHeading}>
          {t('apiKey')}
        </h5>

        <InputGroup inside className={styles.appInfoRow} size={'lg'}>
          <Input value={productUsageDetails.apiKey ?? ''} disabled={true} />
          <InputGroup.Addon>
            <Whisper
              trigger="hover"
              placement={"top"}
              speaker={
                <Tooltip>
                  {t(apiKeyCopied ? 'copied' : 'copy')}
                </Tooltip>
              }
            >
              <i className={styles.inputAddon} onClick={copyApiKeyHandler}>
                <Icon fixedWidth={true} icon={apiKeyCopied ? "check" : "copy"} />
              </i>
            </Whisper>
          </InputGroup.Addon>
        </InputGroup>

        <h5 className={styles.appInfoRowHeading}>
          {t('appSecret')}
        </h5>

        <InputGroup inside className={styles.appInfoRow} size={'lg'}>
          <Input value={appSecretText ?? ''} disabled={true} />
          <InputGroup.Addon>
            {regenerateAddon}
            <Whisper
              trigger="hover"
              placement={"top"}
              speaker={
                <Tooltip>
                  {t(isSecretVisible ? 'hide' : 'show')}
                </Tooltip>
              }
            >
              <i className={styles.inputAddon} onClick={toggleSecretKeyVisibility}>
                <Icon fixedWidth={true} icon={isSecretVisible ? "eye-slash" : "eye"} />
              </i>
            </Whisper>
            <Whisper
              trigger="hover"
              placement={"top"}
              speaker={
                <Tooltip>
                  {t(appSecretCopied ? 'copied' : 'copy')}
                </Tooltip>
              }
            >
              <i className={styles.inputAddon} onClick={copyAppSecretHandler}>
                <Icon fixedWidth={true} icon={appSecretCopied ? "check" : "copy"} />
              </i>
            </Whisper>
          </InputGroup.Addon>
        </InputGroup>

        <h5 className={styles.appInfoRowHeading}>
          {t('domain')}
        </h5>

        <InputGroup inside className={styles.appInfoRow} size={'lg'}>
          <Input value={tempDomain} onChange={onTempDomainChanged} disabled={changingDomain} />
          <InputGroup.Addon>
            {saveDomainAddon}
            <Whisper
              trigger="hover"
              placement={"top"}
              speaker={
                <Tooltip>
                  {t(domainCopied ? 'copied' : 'copy')}
                </Tooltip>
              }
            >
              <i className={styles.inputAddon} onClick={copyDomainHandler}>
                <Icon fixedWidth={true} icon={domainCopied ? "check" : "copy"} />
              </i>
            </Whisper>
          </InputGroup.Addon>
        </InputGroup>

      </Col>
      <Col xs={24} md={12}>

        <Row>
          <Col xs={24} md={12}>
            <ModuleStatisticsMolecule
              title={t('totalMessages')}
              showProgress={true}
              progressCurrentCount={1}
              progressMaxCount={100}
            />
          </Col>
          <Col xs={24} md={12}>
            <ModuleStatisticsMolecule
              title={t('totalUsers')}
              showProgress={true}
              progressCurrentCount={1}
              progressMaxCount={100}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
});

export default ProductUsageDetailsPage;
export {ProductUsageDetailsPage};
