import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {ThemeSwitcherProvider} from 'react-css-theme-switcher';
import {Config} from "./config";
import {Entry} from "./components/pages/shared/entry";
import {localStorageHelper} from "./helpers/localStorage";

let defaultTheme = localStorageHelper.UiTheme;

if( defaultTheme === null ) {
  defaultTheme = Config.defaults.theme;
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeSwitcherProvider defaultTheme={defaultTheme} themeMap={Config.themes}>
      <Entry />
    </ThemeSwitcherProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
