import { eLocalStorageKeys } from "./localStorage.types";

class LocalStorageHelper {
  #userJwt: string | null;
  #uiTheme: string | null;
  #uiLanguage: string | null;

  constructor() {
    this.#userJwt = null;
    this.#uiTheme = null;
    this.#uiLanguage = null;
  }

  get UserJwt(): string | null {
    if( this.#userJwt === null ) {
      this.#userJwt = localStorage.getItem(eLocalStorageKeys.USER_JWT);
    }

    return this.#userJwt;
  }

  set UserJwt(newValue) {
    if( newValue === null ) {
      localStorage.removeItem(eLocalStorageKeys.USER_JWT);
    } else {
      localStorage.setItem(eLocalStorageKeys.USER_JWT, newValue);
    }

    this.#userJwt = newValue;
  }

  get UiTheme(): string | null {
    if( this.#uiTheme === null ) {
      this.#uiTheme = localStorage.getItem(eLocalStorageKeys.UI_THEME);
    }

    return this.#uiTheme;
  }

  set UiTheme(newValue) {
    if( newValue === null ) {
      localStorage.removeItem(eLocalStorageKeys.UI_THEME);
    } else {
      localStorage.setItem(eLocalStorageKeys.UI_THEME, newValue);
    }

    this.#uiTheme = newValue;
  }

  get UiLanguage(): string | null {
    if( this.#uiLanguage === null ) {
      this.#uiLanguage = localStorage.getItem(eLocalStorageKeys.UI_LANGUAGE);
    }

    return this.#uiLanguage;
  }

  set UiLanguage(newValue) {
    if( newValue === null ) {
      localStorage.removeItem(eLocalStorageKeys.UI_LANGUAGE);
    } else {
      localStorage.setItem(eLocalStorageKeys.UI_LANGUAGE, newValue);
    }

    this.#uiLanguage = newValue;
  }
}

export const localStorageHelper = new LocalStorageHelper();
