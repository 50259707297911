import EnglishTranslation from './en.json';
import TurkishTranslation from './tr.json';
import { Language } from "../declarations/language";

const resources = {
  ...EnglishTranslation,
  ...TurkishTranslation
}

export const getAvailableLanguages = () => {
  const availableLanguages: Language[] = [];

  for(let [shortName, language] of Object.entries(resources) )
  {
    availableLanguages.push({
      shortName,
      englishName: language.englishName,
      nativeName: language.nativeName
    });
  }

  return availableLanguages;
}

export default resources;
export {resources};
