import {memo, NamedExoticComponent} from "react";
import {ErrorTemplateProps} from "./errorTemplate.types";
import styles from "./errorTemplate.module.scss";
import {Placeholder} from "rsuite";

export const ErrorTemplate: NamedExoticComponent<ErrorTemplateProps> = memo((props) => {
  const {image, heading, description, footer} = props;

  const imageComponent = (() => {
    if( image == null ) {
      return (
        <div className={styles.imageContainerWithPlaceholderStyles}>
          <Placeholder.Graph/>
        </div>
      )
    }

    return (
      <div className={styles.imageContainerStyles}>
        {image}
      </div>
    );
  })();

  const headingComponent = (() => {
    if( heading == null ) {
      return (
        <h1 className={styles.headingContainerWithPlaceholderStyles}>
          <Placeholder.Paragraph graph={false} rows={1}/>
        </h1>
      )
    }

    return (
      <h1 className={styles.headingContainerStyles}>
        {heading}
      </h1>
    );
  })();

  const paragraphComponent = (() => {
    if( description == null ) {
      return (
        <p className={styles.paragraphContainerWithPlaceholderStyles}>
          <Placeholder.Paragraph graph={false} rows={3}/>
        </p>
      )
    }

    return (
      <p className={styles.paragraphContainerStyles}>
        {description}
      </p>
    );
  })();

  return (
    <div className={styles.mainContainerStyles}>
      {imageComponent}
      {headingComponent}
      {paragraphComponent}
      {footer}
    </div>
  )
})
