import React from "react";
import {Container, Content, FlexboxGrid, Footer} from "rsuite";
import styles from './authLayout.module.scss';
import HeaderOrganism from "../../organisms/headerOrganism/headerOrganism";

export const AuthLayout = (props: any) => {
  return (
    <div className={["show-fake-browser", "login-page", styles.content].join(" ")}>
      <Container className={styles.container}>
        <HeaderOrganism/>
        <Content>
          {props.children}
        </Content>
        <Footer>
          <FlexboxGrid align={"middle"} justify={'center'} className={styles.footer}>
            <FlexboxGrid.Item>
              Copyright FOSH &copy; 2021
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Footer>
      </Container>
    </div>
  );
}
