import React, {memo} from "react";
import {ErrorTemplate} from "../../../templates/error/errorTemplate";
import {useTranslation} from "react-i18next";
import Space404 from "../../../../assets/lottie/404-woman.json";
import LottiePlay from "lottie-react";
import styles from './error404Page.module.scss';

export const Error404Page = memo(() => {
  const {t} = useTranslation();

  return (
    <ErrorTemplate
      image={
        <LottiePlay
          animationData={Space404}
          className={styles.lottiePlay}
        />
      }
      heading={t('pageNotFound')}
      description={t('pageNotFoundDescription')}
    />
  )
});
