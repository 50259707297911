import React, {memo, useState} from "react";
import styles from "../../templates/dashboardLayout/dashboardLayout.module.scss";
import {Icon, Nav, Navbar, Sidebar, Sidenav} from "rsuite";
import {useHistory} from "react-router";
import PagePath from "../../../routing/pagePath";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const SidebarOrganism = memo(() => {
  const {t} = useTranslation();
  const [expand, setExpand] = useState(true);
  const history = useHistory();

  const handleToggle = () => {
    setExpand(!expand);
  }

  const isSidebarButtonActive = (pathName: string) => {
    return history.location.pathname.startsWith(pathName);
  }

  const NavToggle = ({ expand, onChange }: {expand: any, onChange: any}) => {
    return (
      <Navbar className={["nav-toggle", styles.sidebarBottomArea].join(' ')}>
        <Navbar.Body>
          <Nav pullRight>
            <Nav.Item onClick={onChange} style={{ width: 56, textAlign: 'center' }}>
              <Icon icon={expand ? 'angle-left' : 'angle-right'} />
            </Nav.Item>
          </Nav>
        </Navbar.Body>
      </Navbar>
    );
  };

  return (
    <Sidebar
      className={styles.sidebar}
      width={expand ? 260 : 56}
      collapsible
    >
      <Sidenav expanded={expand} appearance={"default"}>
        <Sidenav.Body>
          <Nav>
            <Nav.Item
              componentClass={Link}
              to={PagePath.PRODUCTS}
              eventKey="1"
              active={isSidebarButtonActive(PagePath.PRODUCTS)}
              icon={<Icon icon="group" />}
            >
              {t('products')}
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
      <NavToggle expand={expand} onChange={handleToggle} />
    </Sidebar>
  )
});

export default SidebarOrganism;
export {SidebarOrganism};
