import React, {useEffect, useState} from 'react';
import {Button, FlexboxGrid, Loader, Panel} from "rsuite";
import styles from './productsAndProductUsages.module.scss';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import PagePath from "../../../../routing/pagePath";
import {Product} from "../../../../services/api-service";
import {ErrorTemplate} from "../../../templates/error/errorTemplate";
import LottiePlay from "lottie-react";
import ProductNotFound from "../../../../assets/lottie/product-not-found.json";
import {ProductQueriesProduct} from '@foshteam/fosh-saas-api-client';

const ProductsAndProductUsages = () => {
  const {t} = useTranslation();
  const [isLoading, setLoadingState] = useState(true);
  const [isFailed, setFailState] = useState(false);
  const [, setNextPageAvailabilityState] = useState(false);
  const [products, setProducts] = useState<ProductQueriesProduct[]>([]);

  const loadProducts = async (lastId?: number) => {
    setLoadingState(true);
    setFailState(false);
    try {
      const result = await Product.listProducts()
      setProducts(result.data.products);
      setNextPageAvailabilityState(result.data.pagination.isNextPageAvailable ?? false);
    } catch(e) {
      setFailState(true);
    } finally {
      setLoadingState(false);
    }
  }

  const onRetryButtonPressed = async () => {
    await loadProducts();
  }

  useEffect(() => {
    (async () => {
      await loadProducts();
    })();
  }, []);

  if( isLoading ) {
    return (
      <FlexboxGrid justify={"center"} align={"middle"} className={styles.loadingArea}>
        <FlexboxGrid.Item>
          <Loader
            content={t('productsLoading')}
            vertical={true}
            size="md"
            center={false}
            backdrop={false}
            speed={"normal"}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    )
  }

  if( isFailed ) {
    return (
      <ErrorTemplate
        image={
          <LottiePlay
            animationData={ProductNotFound}
            className={styles.lottiePlay}
          />
        }
        heading={t('productsLoadFailedHeading')}
        description={t('productsLoadFailedDescription')}
        footer={
          <Button size={"lg"} appearance={"primary"} className={styles.retryButton} onClick={onRetryButtonPressed}>
            {t('retry')}
          </Button>
        }
      />
    )
  }

  return (
    <FlexboxGrid>
      {products.map((item) => {
        return (
          <FlexboxGrid.Item key={item.productId}>
            <Panel shaded bordered bodyFill style={{marginLeft: 8, marginRight: 8, width: 240 }}>
              <img alt={item.title ?t(item.title) : ''} src={item.image ? item.image : ''} height="240" />
              <Panel header={item.title}>
                <p>
                  {item.description}
                </p>
              </Panel>
              <Button
                componentClass={Link}
                to={PagePath.PRODUCT_DETAILS.replace(':id', item.productId ? item.productId.toString() : '')}
                appearance={"primary"}
                block={true}
                className={styles.detailsButton}
              >
                {t('goToDetails')}
              </Button>
            </Panel>
          </FlexboxGrid.Item>
        )
      })}
    </FlexboxGrid>
  )
}

export default ProductsAndProductUsages;
export {ProductsAndProductUsages};
