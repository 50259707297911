import React from "react";
import {Container, Content} from "rsuite";
import styles from './dashboardLayout.module.scss';
import HeaderOrganism from "../../organisms/headerOrganism/headerOrganism";
import SidebarOrganism from "../../organisms/sidebarOrganism/sidebarOrganism";

export const DashboardLayout = (props: any) => {
  return (
    <div className={["show-fake-browser", "sidebar-page", styles.content].join(" ")}>
      <Container className={styles.container}>
        <SidebarOrganism/>
        <Container>
          <HeaderOrganism/>
          <Content className={styles.contentArea}>
            {props.children}
          </Content>
        </Container>
      </Container>
    </div>
  );
}
