import LanguageDetector, { DetectorOptions } from "i18next-browser-languagedetector";
import { eLocalStorageKeys } from "./helpers/localStorage.types";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { resources } from "./translations/resources";
import { Config } from "./config";

const initTranslations = async () => {
  const browserLanguageDetectorOptions: DetectorOptions = {
    order: ['querystring', 'localStorage', 'navigator'],

    lookupQuerystring: 'lang',
    lookupLocalStorage: eLocalStorageKeys.UI_LANGUAGE,

    caches: ['localStorage']
  };

  await i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources,
      fallbackLng: Config.defaults.language,
      detection: browserLanguageDetectorOptions,
      interpolation: {
        escapeValue: false
      }
    });
}

(async () => {
  await initTranslations();
})();
