export const Config = {
  defaults: {
    theme: 'light',
    language: 'en'
  },

  themes: {
    light: 'https://cdnjs.cloudflare.com/ajax/libs/rsuite/4.9.2/styles/rsuite-default.css',
    dark: 'https://cdnjs.cloudflare.com/ajax/libs/rsuite/4.9.2/styles/rsuite-dark.min.css'
  },

  api: {
    baseUrl: process.env.NODE_ENV === 'production' ? 'https://saasapi.fosh.ml' : 'https://localhost:5001'
  }
}
