const PagePath = {
  HOME: '/',
  NOT_FOUND: '/404',

  LOGIN: '/auth/login',
  LOST_PASSWORD: '/auth/lostPassword',
  RECOVER_PASSWORD: '/auth/recoverPassword',
  REGISTER: '/auth/signUp',
  PASSWORD_RECOVERED: '/auth/passwordRecovered',

  DASHBOARD: '/panel/dashboard',
  PRODUCTS: '/panel/products',
  PRODUCT_DETAILS: '/panel/products/:id',

  PRODUCT_USAGE_DETAILS: '/panel/product_usages/:id',
};

const DynamicPagePath = {
  AUTH_ITEMS: '/auth/*',
  DASHBOARD_ITEMS: '/panel/*'
}

export default PagePath;
export {PagePath};
export {DynamicPagePath};
