import { localStorageHelper } from "../helpers/localStorage";
import PagePath from "./pagePath";

export const RequireLogin = (to: any, from: any, next: any) => {
  if (to.meta.auth) {
    if (localStorageHelper.UserJwt !== null) {
      next();
    } else {
      next.redirect(PagePath.LOGIN);
    }
  } else {
    next();
  }
};
